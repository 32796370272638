import React from 'react';
import { connect } from 'react-redux';
import { selectCategory } from '../../actions/categories';
import { fetchTops } from '../../actions/lists';

import ListCard from '../../components/lists/ListCard';
import CardSlider from '../../components/CardSlider/CardSlider';
import Spinner from '../../components/Spinner';

import './HomePage.scss';

class HomePage extends React.Component {
	componentDidMount() {
		this.props.selectCategory({});
		this.props.fetchTops();
	}

	getCategoryName(categoryId) {
		let categoryName;
		this.props.categories.forEach(category => {
			const query = category.items.find(item => item.id === categoryId);
			if (query) categoryName = query.name;
		});
		return categoryName;
	}

	renderCategories() {
		return this.props.lists.map(item => (
			<div
				key={item.id}
				className="eight wide tablet five wide computer column"
				style={{ padding: '20px' }}
			>
				<ListCard
					list={item}
					header={`${this.getCategoryName(item.categoryId)}`}
				/>
			</div>
		));
	}

	renderCards() {
		return (
			<>
				<div className="ui basic segment">
					<CardSlider lists={this.props.lists} />
				</div>
				<div className="ui basic segment">
					<div className="ui centered stackable grid">
						{this.renderCategories()}
					</div>
				</div>
			</>
		);
	}

	render() {
		return (
			<>
				{<Spinner hide={!this.props.loading} />}
				<div style={{ paddingTop: '30px' }}>
					<div className="home-description">
						Find awesome lists, upvote and save your favorites
						<br />
						<span className="home-text">Create and Share your lists</span>
					</div>
					{!this.props.loading && this.renderCards()}
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	let categories = JSON.parse(JSON.stringify(state.categories));
	categories = Object.values(categories);
	categories.map(cat => (cat.items = Object.values(cat.items, 'id')));

	return {
		categories,
		lists: state.tops.response,
		loading: state.tops.loading
	};
};

export default connect(
	mapStateToProps,
	{ selectCategory, fetchTops }
)(HomePage);
