import React from 'react';
import { connect } from 'react-redux';
import { selectCategory } from '../../actions/categories';
import { fetchFavoriteLists } from '../../actions/lists';

import Spinner from '../../components/Spinner';
import InfiniteList from '../../components/lists/InfiniteList';

class FavoritesPage extends React.Component {
	componentDidMount() {
		this.props.selectCategory({});

		if (this.props.currentUserId) {
			this.props.fetchFavoriteLists(1);
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.currentUserId !== this.props.currentUserId) {
			this.props.fetchFavoriteLists(1);
		}
	}

	loadMore = () => {
		if (!this.props.loadingMore && !this.props.allLoaded) {
			this.props.fetchFavoriteLists(this.props.pagination + 1);
		}
	};

	renderList() {
		if (this.props.loading) {
			return (
				<div style={{ height: '60vh' }}>
					<Spinner />
				</div>
			);
		}

		return (
			<div className="ui basic segment">
				<InfiniteList
					items={this.props.lists}
					loadMore={this.loadMore}
					loadingMore={this.props.loadingMore}
				/>
			</div>
		);
	}

	render() {
		return (
			<div>
				<div className="ui basic segment">
					<div className="ui center aligned header list-header">
						<div>Favorites</div>
					</div>
				</div>
				{this.renderList()}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		lists: state.lists.response,
		loading: state.lists.loading,
		pagination: state.lists.page,
		loadingMore: state.lists.loadingMore,
		allLoaded: state.lists.allLoaded,
		currentUserId: state.auth.user.userId
	};
};

export default connect(
	mapStateToProps,
	{ selectCategory, fetchFavoriteLists }
)(FavoritesPage);
