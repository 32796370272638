import { CATEGORY_SELECTED } from '../actions/types';

export default (state = {}, action) => {
	switch (action.type) {
		case CATEGORY_SELECTED:
			return {
				...state,
				selectedCategory: action.payload.category.categoryId
			};
		default:
			return state;
	}
};
