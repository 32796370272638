/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import { signInOpen } from '../../actions/auth';
import { voteList, favoriteList, openCreateList } from '../../actions/lists';
import { Transition, Popup } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import './ListCard.scss';

class ListCard extends React.Component {
	state = { visible: false };
	timeout;

	componentWillUnmount() {
		clearTimeout(this.timeout);
	}

	voteList(value) {
		if (this.props.isSignedIn) {
			let increment = value;

			if (this.props.myVotes === value) {
				this.props.voteList(this.props.list.id, 0, -value);
			} else {
				if (this.props.myVotes === -1 && value === 1) {
					increment = 2;
				} else if (this.props.myVotes === 1 && value === -1) {
					increment = -2;
				}
				this.props.voteList(this.props.list.id, value, increment);
			}
		} else {
			this.props.signInOpen();
		}
	}

	addFavorite = () => {
		if (this.props.isSignedIn) {
			this.props.favoriteList(this.props.list.id, !this.props.isFavorite);
		} else {
			this.props.signInOpen();
		}
	};

	getItems() {
		return this.props.list.items.map((item, index) => {
			return (
				<div key={item.id} className="list-item text-ellipsis">
					<Popup
						content="Search with Google"
						position="right center"
						trigger={
							<a
								className="item blue-item"
								href={`http://www.google.com/search?q=${item.name}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								<i
									className={`trophy icon icon-trophy ${
										index === 0 ? 'yellow' : index === 1 ? 'grey' : 'brown'
									}`}
								/>
								<span className="text-item">{item.name}</span>
							</a>
						}
					/>
				</div>
			);
		});
	}

	renderTitle() {
		if (this.props.header) {
			return (
				<div className="category-header text-ellipsis">
					<img src="/assets/img/prize.png" className="icon-prize" alt="prize" />
					{this.props.header}
				</div>
			);
		}
	}

	renderHeader(cardColor, user, userId, avatarId, isCreator) {
		return (
			<div
				className="content text-ellipsis white-text"
				style={{ backgroundColor: cardColor.header }}
			>
				{isCreator &&
					this.props.list.popularity < 2 &&
					this.props.list.popularity > -2 && (
						<a
							onClick={() =>
								this.props.openCreateList(
									this.props.list.categoryId,
									this.props.list,
									true
								)
							}
							className="right floated meta"
						>
							<i className="edit outline icon edit-icon" />
						</a>
					)}
				<Link to={`/user/lists/${user.userId}`}>
					<img
						className="ui avatar image avatar-img"
						src={`https://robohash.org/${
							user.userId === userId ? avatarId : user.avatarId
						}`}
						alt="avatar"
					/>
				</Link>
				<Link to={`/user/lists/${user.userId}`} className="user-text">
					{user.username}
				</Link>
			</div>
		);
	}

	renderFooter(cardColor, popularity) {
		return (
			<div
				className="content bottom-content"
				style={{ backgroundColor: cardColor.footer }}
			>
				<span className="left floated">
					<button
						className={`ui icon basic button vote-button ${
							this.props.myVotes === 1 ? 'green' : ''
						}`}
						onClick={() => this.voteList(1)}
					>
						<i className="arrow alternate circle up outline icon" />
					</button>

					<div className="vote-count">{popularity}</div>

					<button
						className={`ui icon basic button vote-button ${
							this.props.myVotes === -1 ? 'red' : ''
						}`}
						onClick={() => this.voteList(-1)}
					>
						<i className="arrow alternate circle down outline icon" />
					</button>
				</span>
				<span
					className="right floated star star-icon"
					onClick={this.addFavorite}
				>
					<i className={`star icon ${this.props.isFavorite ? 'active' : ''}`} />
				</span>
			</div>
		);
	}

	render() {
		const { user, creatorId, popularity = 0 } = this.props.list;
		const { userId, avatarId } = this.props.currentUser;
		const isCreator = creatorId === userId;

		let cardColor = {
			header: 'rgb(55, 39, 119)',
			footer: 'rgba(55, 39, 119, 0.08)'
		};
		if (isCreator) {
			cardColor = {
				header: 'rgb(33, 133, 208)',
				footer: 'rgba(33, 133, 208, 0.08)'
			};
		}

		this.timeout = setTimeout(() => {
			if (!this.state.visible) this.setState({ visible: true });
		}, (this.props.index % 15) * 270);

		return (
			<Transition visible={this.state.visible} animation="zoom" duration={500}>
				<div>
					{this.renderTitle()}
					<div
						className="ui card list-card"
						style={{
							boxShadow:
								'2px 2px 2px 0 rgb(55, 39, 119), 0 0 0 0px rgb(55, 39, 119)'
						}}
					>
						{this.renderHeader(cardColor, user, userId, avatarId, isCreator)}
						<div className="content" style={{ padding: '0 20px' }}>
							<div className="description">
								<div className="ui list">{this.getItems()}</div>
							</div>
						</div>
						{this.renderFooter(cardColor, popularity)}
					</div>
				</div>
			</Transition>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		isSignedIn: state.auth.isSignedIn,
		currentUser: state.auth.user,
		lists: state.lists,
		myVotes: state.auth.user.votes
			? state.auth.user.votes[ownProps.list.id]
			: null,
		isFavorite: state.auth.user.favorites
			? state.auth.user.favorites[ownProps.list.id]
			: null
	};
};

export default connect(
	mapStateToProps,
	{ voteList, favoriteList, signInOpen, openCreateList }
)(ListCard);
