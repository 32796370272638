import React from 'react';
import { connect } from 'react-redux';
import {
	signInStart,
	fetchCurrentUser,
	signInError,
	profileClose,
	updateProfile,
	updateEmail,
	updatePassword
} from '../../actions/auth';
import { Button, Modal, TransitionablePortal } from 'semantic-ui-react';

import ProfileForm from './ProfileForm';

import './ProfilePage.scss';

class ProfilePage extends React.Component {
	state = { avatarId: null, loadingAvatar: true };

	closeModal = () => this.props.profileClose();

	onSubmit = async formValues => {
		this.props.signInStart();

		const { currentUser } = this.props;

		try {
			this.props.updateProfile(
				formValues.username,
				this.state.avatarId || this.props.currentUser.avatarId
			);
		} catch (error) {
			this.props.signInError(error.message);
		}

		if (formValues.email !== currentUser.email) {
			try {
				this.props.updateEmail(formValues.email);
			} catch (error) {
				this.props.signInError(error.message);
			}
		}

		if (formValues.newPassword) {
			try {
				this.props.updatePassword(
					formValues.email,
					formValues.currentPassword,
					formValues.newPassword
				);
			} catch (error) {
				this.props.signInError(error.message);
			}
		}
	};

	handleImageLoaded = () => {
		this.setState({ loadingAvatar: false });
	};

	changeAvatar = () => {
		const avatarId = Math.floor(10000000 + Math.random() * 900000);
		this.setState({ avatarId, loadingAvatar: true });
	};

	render() {
		const { currentUser } = this.props;

		return (
			<TransitionablePortal
				open={this.props.openModal}
				onClose={this.closeModal}
				transition={{ animation: 'scale', duration: 500 }}
			>
				<Modal size="tiny" open={true}>
					<Modal.Content>
						<Modal.Description>
							<div className="ui basic segment center aligned container-avatar">
								<img
									className="ui avatar profile-avatar"
									src={`https://robohash.org/${this.state.avatarId ||
										this.props.currentUser.avatarId}`}
									alt="avatar"
									onLoad={this.handleImageLoaded.bind(this)}
								/>
							</div>
							<div className="ui basic segment center aligned container-shuffle">
								<Button
									primary
									loading={this.state.loadingAvatar}
									onClick={this.changeAvatar}
								>
									Shuffle Avatar
								</Button>
							</div>
							<div className="ui basic segment">
								<ProfileForm
									onSubmit={this.onSubmit}
									initialValues={{
										username: currentUser.username,
										email: currentUser.email
									}}
								/>
							</div>
						</Modal.Description>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.closeModal}>Cancel</Button>
						<Button
							form="createForm"
							color="violet"
							content="Update"
							disabled={this.props.loadingAuth}
							loading={this.props.loadingAuth}
						/>
					</Modal.Actions>
				</Modal>
			</TransitionablePortal>
		);
	}
}

const mapStateToProps = state => {
	return {
		currentUser: state.auth.user,
		loadingAuth: state.auth.loadingAuth,
		openModal: state.auth.openProfileModal
	};
};

export default connect(
	mapStateToProps,
	{
		signInStart,
		fetchCurrentUser,
		signInError,
		profileClose,
		updateProfile,
		updateEmail,
		updatePassword
	}
)(ProfilePage);
