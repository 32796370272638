export const SIGN_IN_CLOSE = 'SIGN_IN_CLOSE';
export const SIGN_IN_OPEN = 'SIGN_IN_OPEN';
export const LOG_IN_OPEN = 'LOG_IN_OPEN';
export const LOG_IN_CLOSE = 'LOG_IN_CLOSE';
export const SIGN_IN_START = 'SIGN_IN_START';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const RESET_PASSWORD_OPEN = 'RESET_PASSWORD_OPEN';
export const RESET_PASSWORD_CLOSE = 'RESET_PASSWORD_CLOSE';

export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
export const PROFILE_OPEN = 'PROFILE_OPEN';
export const PROFILE_CLOSE = 'PROFILE_CLOSE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';

export const FETCH_LISTS_START = 'FETCH_LISTS_START';
export const FETCH_LISTS_MORE = 'FETCH_LISTS_MORE';
export const FETCH_LISTS = 'FETCH_LISTS';
export const CREATE_LIST_START = 'CREATE_LIST_START';
export const CREATE_LIST = 'CREATE_LIST';
export const VOTE_LIST = 'VOTE_LIST';
export const VOTE_LIST_START = 'VOTE_LIST_START';
export const FAVORITE_LIST_START = 'FAVORITE_LIST_START';
export const FAVORITE_LIST = 'FAVORITE_LIST';
export const USER_LISTS = 'USER_LISTS';
export const FETCH_TOPS = 'FETCH_TOPS';
export const FETCH_TOPS_START = 'FETCH_TOPS_START';

export const CREATE_OPEN = 'CREATE_OPEN';
export const CREATE_CLOSE = 'CREATE_CLOSE';

export const CATEGORY_SELECTED = 'CATEGORY_SELECTED';
export const CATEGORY_VIEW = 'CATEGORY_VIEW';
