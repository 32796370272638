import React from 'react';
import { Visibility } from 'semantic-ui-react';

import Spinner from '../Spinner';
import ListCard from './ListCard';

class InfiniteList extends React.Component {
	handleUpdate = (e, { calculations }) => {
		if (calculations.bottomVisible) this.props.loadMore();
	};

	getItems() {
		return this.props.items.map((item, index) => (
			<div
				key={item.id}
				className="eight wide tablet five wide computer column"
				style={{ padding: '20px' }}
			>
				<ListCard list={item} index={index} />
			</div>
		));
	}

	render() {
		if (!this.props.items.length) {
			return (
				<div className="ui basic segment">
					<div className="ui center aligned header list-empty">
						{/* Nothing Yet */}
						<img className="img-empty" src="/assets/img/sad.png" alt="sad" />
					</div>
				</div>
			);
		}

		return (
			<div>
				<Visibility onUpdate={this.handleUpdate}>
					<div className="ui centered stackable grid">{this.getItems()}</div>
				</Visibility>
				{this.props.loadingMore && (
					<div className="ui basic segment">
						<Spinner />
					</div>
				)}
			</div>
		);
	}
}

export default InfiniteList;
