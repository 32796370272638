import React from 'react';
import { connect } from 'react-redux';
import { signIn } from '../../actions/auth';

import { auth, googleAuthProvider } from '../../apis/Firestore';

class GoogleAuth extends React.Component {
	onSignInClick = async () => {
		const authResult = await auth.signInWithPopup(googleAuthProvider);

		const authInfo = {
			userId: authResult.user.uid,
			username: authResult.user.providerData[0].displayName,
			email: authResult.user.providerData[0].email,
			providerId: authResult.user.providerData[0].providerId
		};

		this.props.signIn(authInfo);
	};

	render() {
		return (
			<div onClick={this.onSignInClick} className="ui red google button">
				<i className="google icon" />
				Sign In with Google
			</div>
		);
	}
}

const mapStateToProps = state => {
	return { isSignedIn: state.auth.isSignedIn };
};

export default connect(
	mapStateToProps,
	{ signIn }
)(GoogleAuth);
