import { firebase, db } from '../apis/Firestore';
import _ from 'lodash';

import {
	FETCH_LISTS_START,
	FETCH_LISTS_MORE,
	FETCH_LISTS,
	CREATE_LIST_START,
	CREATE_LIST,
	VOTE_LIST_START,
	VOTE_LIST,
	FAVORITE_LIST_START,
	FAVORITE_LIST,
	USER_LISTS,
	FETCH_TOPS,
	FETCH_TOPS_START,
	CREATE_OPEN,
	CREATE_CLOSE
} from './types';

const PER_PAGE = 15;
const DEFAULT_FILTER_ID = 'popularity';

const fetchListsStart = () => {
	return {
		type: FETCH_LISTS_START
	};
};

const fetchListsMore = () => {
	return {
		type: FETCH_LISTS_MORE
	};
};

export const fetchLists = (
	categoryId,
	page,
	userId = null,
	filterId = DEFAULT_FILTER_ID,
	place = {}
) => async (dispatch, getState) => {
	if (page === 1) dispatch(fetchListsStart());
	else dispatch(fetchListsMore());

	let query = db
		.collection('lists')
		.orderBy(filterId, 'desc')
		.limit(page * PER_PAGE);

	if (categoryId) {
		query = query.where('categoryId', '==', categoryId);
	}

	if (userId) {
		query = query.where('creatorId', '==', userId);
	}

	if (place && place.place_id) {
		query = query.where('place.place_id', '==', place.place_id);
	}

	if (getState().lists.lastVisible)
		query = query.startAfter(getState().lists.lastVisible);

	const listsResponse = await query.get();
	const lists = await fetchListsUsers(listsResponse);

	const lastVisible = listsResponse.docs[listsResponse.docs.length - 1];
	const allLoaded = lists.length !== PER_PAGE;

	dispatch({
		type: FETCH_LISTS,
		payload: { lists, page, lastVisible, allLoaded, filterId, place }
	});
};

export const fetchListsUsers = async response => {
	const rawUsers = [];

	response.forEach(list => {
		if (list.data() && list.data().creatorId) {
			rawUsers.push(
				db
					.collection('users')
					.doc(list.data().creatorId)
					.get()
			);
		}
	});

	const fetchUsers = await Promise.all(rawUsers);

	const users = [];

	fetchUsers.forEach(user => {
		users.push(user.data());
	});

	const lists = [];

	response.forEach(list => {
		if (!list.data()) return;

		const user = users.find(user => user.userId === list.data().creatorId);

		lists.push({
			...list.data(),
			...{ id: list.id, user: _.pick(user, 'userId', 'username', 'avatarId') }
		});
	});

	return lists;
};

const createListStart = () => {
	return {
		type: CREATE_LIST_START
	};
};

export const createList = (
	formValues,
	categoryId,
	place = {},
	listId = null
) => async (dispatch, getState) => {
	dispatch(createListStart());

	const userId = getState().auth.user.userId;

	const items = _.values(formValues).map((item, index) => {
		return { id: index, name: item };
	});

	const newList = {
		creatorId: userId,
		categoryId,
		popularity: 0,
		createdOn: firebase.firestore.FieldValue.serverTimestamp(),
		items
	};

	if (place && place.place_id) newList.place = place;

	if (listId) {
		await db
			.collection('lists')
			.doc(listId)
			.update(newList);
	} else {
		const listResponse = await db.collection('lists').add(newList);
		await dispatch(voteList(listResponse.id, 1, 1));
	}

	dispatch({
		type: CREATE_LIST
	});

	if (getState().tops.response.length) {
		dispatch(fetchTops(1));
	} else if (getState().lists.favorites) {
		dispatch(fetchFavoriteLists(1));
	} else if (getState().lists.user.username) {
		dispatch(fetchUserLists(1, userId, getState().lists.categoryId));
	} else {
		let listUserId = null;
		if (getState().lists.user.username) {
			categoryId = null;
			listUserId = userId;
		}
		dispatch(fetchLists(categoryId, 1, listUserId, DEFAULT_FILTER_ID, place));
	}
};

const voteListStart = (listId, value, increment) => {
	return {
		type: VOTE_LIST_START,
		payload: { id: listId, value, increment }
	};
};

export const voteList = (listId, value, increment) => async (
	dispatch,
	getState
) => {
	dispatch(voteListStart(listId, value, increment));

	const userId = getState().auth.user.userId;
	const doIncrement = firebase.firestore.FieldValue.increment(increment);

	if (value === 0) value = firebase.firestore.FieldValue.delete();

	await db
		.collection('users')
		.doc(userId)
		.set(
			{
				votes: { [listId]: value }
			},
			{ merge: true }
		);

	await db
		.collection('lists')
		.doc(listId)
		.update({ popularity: doIncrement });

	dispatch({
		type: VOTE_LIST,
		payload: listId
	});
};

const favoriteListStart = (listId, value) => {
	return {
		type: FAVORITE_LIST_START,
		payload: { id: listId, value: value }
	};
};

export const favoriteList = (listId, value) => async (dispatch, getState) => {
	dispatch(favoriteListStart(listId, value));

	const userId = getState().auth.user.userId;

	await db
		.collection('users')
		.doc(userId)
		.set(
			{
				favorites: { [listId]: value || firebase.firestore.FieldValue.delete() }
			},
			{ merge: true }
		);

	dispatch({
		type: FAVORITE_LIST,
		payload: listId
	});
};

export const fetchFavoriteLists = page => async (dispatch, getState) => {
	if (page === 1) dispatch(fetchListsStart());
	else dispatch(fetchListsMore());

	const favoriteLists = _.keys(getState().auth.user.favorites);

	let paginatedList = [...favoriteLists];

	paginatedList = favoriteLists.slice(
		getState().lists.lastVisible || 0,
		PER_PAGE * page
	);

	const listsResponse = await getListsById(paginatedList);
	const lists = await fetchListsUsers(listsResponse);

	const lastVisible = getState().lists.response.length + PER_PAGE;
	const allLoaded = getState().lists.response.length === favoriteLists.length;

	dispatch({
		type: FETCH_LISTS,
		payload: { lists, page, lastVisible, allLoaded, favorites: true }
	});
};

export const fetchUserLists = (
	page,
	userId,
	categoryId = null
) => async dispatch => {
	if (page === 1) dispatch(fetchListsStart());
	else dispatch(fetchListsMore());

	const user = await db
		.collection('users')
		.doc(userId)
		.get();

	dispatch(fetchLists(categoryId, page, userId));

	dispatch({
		type: USER_LISTS,
		payload: { username: user.data().username, categoryId }
	});
};

export const getListsById = async ids => {
	const rawLists = [];

	ids.forEach(id => {
		rawLists.push(
			db
				.collection('lists')
				.doc(id)
				.get()
		);
	});

	return await Promise.all(rawLists);
};

const fetchTopsStart = () => {
	return {
		type: FETCH_TOPS_START
	};
};

export const fetchTops = () => async (dispatch, getState) => {
	dispatch(fetchTopsStart());

	let categories = JSON.parse(JSON.stringify(getState().categories));
	categories = Object.values(categories);
	categories.map(cat => (cat.items = Object.values(cat.items, 'id')));

	const rawTops = [];

	categories.forEach(category => {
		category.items.forEach(item => {
			rawTops.push(
				db
					.collection('lists')
					.where('categoryId', '==', item.id)
					.orderBy('popularity', 'desc')
					.limit(1)
					.get()
			);
		});
	});

	let topsResponse = await Promise.all(rawTops);
	topsResponse = topsResponse.filter(res => !res.empty);

	const topsList = topsResponse.map(res => res.docs[0]);

	const tops = await fetchListsUsers(topsList);

	dispatch({
		type: FETCH_TOPS,
		payload: tops
	});
};

export const openCreateList = (categoryId, list = {}) => {
	return {
		type: CREATE_OPEN,
		payload: { categoryId, list }
	};
};

export const closeCreateList = (formValues = null) => {
	return {
		type: CREATE_CLOSE,
		payload: formValues
	};
};
