import React from 'react';
import { connect } from 'react-redux';
import { signInOpen } from '../../actions/auth';
import { closeCreateList, createList } from '../../actions/lists';
import { Button, Modal, TransitionablePortal } from 'semantic-ui-react';

import LocationList from '../LocationList/LocationList';
import CreateForm from './CreateForm';

class CreateModal extends React.Component {
	state = { place: null, missingCity: null, title: '', new: true };

	onSubmit = formValues => {
		if (this.props.headerId === 'city' && !this.state.place) {
			return this.setState({ missingCity: true });
		}

		if (this.props.isSignedIn) {
			this.props.createList(
				formValues,
				this.props.categoryId,
				this.state.place,
				this.props.editListId
			);
		} else {
			this.props.signInOpen();
			if (formValues) this.props.closeCreateList(formValues);
		}
	};

	componentDidUpdate(prevProps) {
		const { loadingNew } = this.props;
		if (!loadingNew && prevProps.loadingNew !== loadingNew) {
			this.props.closeCreateList();
			this.setState({ place: null });
		}

		if (this.props.title && prevProps.title !== this.props.title) {
			this.setState({ title: this.props.title });
		}

		if (prevProps.editListId !== this.props.editListId) {
			if (this.props.editListId) this.setState({ new: false });
			else {
				setTimeout(() => {
					this.setState({ new: true });
				}, 0);
			}
		}
	}

	selectPlace = place => {
		if (place.city) {
			this.setState({
				place: {
					city: place.city,
					country: place.country,
					place_id: place.place_id
				},
				missingCity: false
			});
		} else {
			this.setState({ place: null });
		}
	};

	render() {
		let initialValues;

		if (this.props.editItems[0]) {
			initialValues = {
				first: this.props.editItems[0],
				second: this.props.editItems[1],
				third: this.props.editItems[2]
			};
		} else if (this.props.formValues) {
			initialValues = this.props.formValues;
		}

		return (
			<TransitionablePortal
				open={this.props.openModal}
				transition={{ animation: 'scale', duration: 500 }}
			>
				<Modal size="tiny" open={true}>
					<Modal.Header>Your Favorite {this.state.title} List</Modal.Header>
					<Modal.Content>
						<Modal.Description>
							{this.props.headerId === 'city' && (
								<div className="ui basic segment search-container">
									<LocationList selectPlace={this.selectPlace} />
									{this.state.missingCity && (
										<div className="ui error message">
											<div className="header">Please choose a city</div>
										</div>
									)}
								</div>
							)}
							<div className="ui basic segment">
								<CreateForm
									onSubmit={this.onSubmit}
									initialValues={initialValues}
								/>
							</div>
						</Modal.Description>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => this.props.closeCreateList()}>Cancel</Button>
						<Button
							form="createForm"
							color="violet"
							content={this.state.new ? 'Create' : 'Update'}
							disabled={this.props.loadingNew}
							loading={this.props.loadingNew}
						/>
					</Modal.Actions>
				</Modal>
			</TransitionablePortal>
		);
	}
}

const mapStateToProps = state => {
	let categories = JSON.parse(JSON.stringify(state.categories));
	categories = Object.values(categories);
	categories.map(cat => (cat.items = Object.values(cat.items, 'id')));

	let headerId;
	let title;

	categories.forEach(cat => {
		cat.items.forEach(item => {
			if (item.id === state.lists.editList.categoryId) {
				headerId = cat.id;
				title = item.name;
			}
		});
	});

	return {
		loadingNew: state.lists.loadingNew,
		isSignedIn: state.auth.isSignedIn,
		openModal: state.lists.openCreateModal,
		categoryId: state.lists.editList.categoryId,
		title: title,
		headerId: headerId,
		editList: state.lists.editList.list,
		editListId: state.lists.editList.list.id,
		editItems: state.lists.editList.list.id
			? state.lists.editList.list.items.map(item => item.name)
			: [],
		formValues: state.lists.formValues
	};
};

export default connect(
	mapStateToProps,
	{ createList, signInOpen, closeCreateList }
)(CreateModal);
