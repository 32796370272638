import {
	SIGN_IN_OPEN,
	SIGN_IN_CLOSE,
	LOG_IN_OPEN,
	LOG_IN_CLOSE,
	SIGN_IN_START,
	SIGN_IN_ERROR,
	SIGN_IN,
	SIGN_OUT,
	FETCH_CURRENT_USER,
	RESET_PASSWORD_OPEN,
	RESET_PASSWORD_CLOSE,
	PROFILE_OPEN,
	PROFILE_CLOSE,
	UPDATE_PROFILE,
	UPDATE_EMAIL,
	UPDATE_PASSWORD,
	VOTE_LIST_START,
	FAVORITE_LIST_START
} from '../actions/types';

const INITIAL_STATE = {
	openLoginModal: null,
	openAuthModal: null,
	openProfileModal: null,
	openPasswordModal: null,
	isSignedIn: null,
	loadingAuth: null,
	errorMessage: null,
	user: {}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SIGN_IN_OPEN:
			return { ...state, openAuthModal: true };
		case SIGN_IN_CLOSE:
			return { ...state, openAuthModal: null, errorMessage: null };
		case SIGN_IN_START:
			return { ...state, loadingAuth: true };
		case SIGN_IN_ERROR:
			return { ...state, errorMessage: action.payload, loadingAuth: false };
		case SIGN_IN:
			return {
				...state,
				isSignedIn: true,
				errorMessage: null
			};
		case SIGN_OUT:
			return { ...state, isSignedIn: false, user: {} };
		case FETCH_CURRENT_USER:
			localStorage.userId = action.payload.userId;
			return {
				...state,
				loadingAuth: false,
				isSignedIn: true,
				errorMessage: null,
				openAuthModal: null,
				openLoginModal: null,
				user: { ...action.payload }
			};
		case LOG_IN_OPEN:
			return { ...state, openLoginModal: true };
		case LOG_IN_CLOSE:
			return { ...state, openLoginModal: null, errorMessage: null };
		case RESET_PASSWORD_OPEN:
			return { ...state, openPasswordModal: true };
		case RESET_PASSWORD_CLOSE:
			return { ...state, openPasswordModal: null, errorMessage: null };
		case PROFILE_OPEN:
			return { ...state, openProfileModal: true };
		case PROFILE_CLOSE:
			return { ...state, openProfileModal: null, errorMessage: null };
		case UPDATE_PROFILE:
			return {
				...state,
				loadingAuth: false,
				errorMessage: null,
				openProfileModal: null,
				user: {
					...state.user,
					username: action.payload.username,
					avatarId: action.payload.avatarId
				}
			};
		case UPDATE_EMAIL:
			return {
				...state,
				loadingAuth: false,
				errorMessage: null,
				openProfileModal: null,
				user: { ...state.user, email: action.payload }
			};
		case UPDATE_PASSWORD:
			return {
				...state,
				loadingAuth: false,
				errorMessage: null,
				openProfileModal: null
			};
		case VOTE_LIST_START:
			return {
				...state,
				user: {
					...state.user,
					votes: {
						...state.user.votes,
						[action.payload.id]: action.payload.value
					}
				}
			};
		case FAVORITE_LIST_START:
			return {
				...state,
				user: {
					...state.user,
					favorites: {
						...state.user.favorites,
						[action.payload.id]: action.payload.value
					}
				}
			};
		default:
			return state;
	}
};
