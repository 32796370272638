import React from 'react';
import { connect } from 'react-redux';
import { selectCategory } from '../../actions/categories';
import { fetchLists, openCreateList } from '../../actions/lists';

import NotFound from '../NotFound/NotFound';
import Spinner from '../../components/Spinner';
import InfiniteList from '../../components/lists/InfiniteList';
import LocationList from '../../components/LocationList/LocationList';

import './ListsPage.scss';

class ListPage extends React.Component {
	state = { filter: 'popularity' };

	componentDidMount() {
		this.updateStates();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.match.url !== this.props.match.url) {
			this.updateStates();
		}
	}

	updateStates() {
		this.setState({ filter: 'popularity' });
		const { headerId, categoryId } = this.props.match.params;
		this.props.selectCategory({ headerId, categoryId });
		this.props.fetchLists(this.props.match.params.categoryId, 1);
	}

	loadMore = () => {
		if (!this.props.loadingMore && !this.props.allLoaded) {
			this.props.fetchLists(
				this.props.match.params.categoryId,
				this.props.pagination + 1,
				null,
				this.props.filterId
			);
		}
	};

	onChangeFilter = value => {
		if (this.props.loading || this.state.filter === value) return;

		this.setState({ filter: value });
		this.props.fetchLists(this.props.match.params.categoryId, 1, null, value);
	};

	selectPlace = place => {
		this.props.fetchLists(
			this.props.match.params.categoryId,
			1,
			null,
			this.props.filterId,
			place
		);
	};

	renderDropdown() {
		return (
			<div className="ui menu inverted violet filter-menu">
				<div
					className={`item ${
						this.props.filterId === 'popularity' ||
						this.state.filter === 'popularity'
							? 'active'
							: ''
					}`}
					value="popularity"
					onClick={() => this.onChangeFilter('popularity')}
				>
					<i className="icon fire orange" />
					Top
				</div>
				<div
					className={`item ${
						this.props.filterId === 'createdOn' ||
						this.state.filter === 'createdOn'
							? 'active'
							: ''
					}`}
					onClick={() => this.onChangeFilter('createdOn')}
				>
					<i className="icon clock outline" />
					Recent
				</div>
			</div>
		);
	}

	renderFilters() {
		return (
			<div className="ui grid">
				{this.renderDropdown()}
				{this.props.match.params.headerId === 'city' && (
					<div className="city-search">
						<LocationList
							selectPlace={this.selectPlace}
							place={this.props.place}
						/>
					</div>
				)}
			</div>
		);
	}

	renderList() {
		if (this.props.loading) {
			return (
				<div style={{ height: '60vh' }}>
					<Spinner />
				</div>
			);
		}

		return (
			<div className="ui basic segment">
				<InfiniteList
					items={this.props.lists}
					loadMore={this.loadMore}
					loadingMore={this.props.loadingMore}
				/>
			</div>
		);
	}

	renderCreateButton() {
		return (
			<div>
				<button
					className="ui button teal create-button"
					onClick={() => this.props.openCreateList(this.props.category.id)}
				>
					<i className="plus icon" />
				</button>
			</div>
		);
	}

	render() {
		if (!this.props.category) {
			return <NotFound />;
		}

		return (
			<div>
				<div className="ui basic segment center aligned">
					<div className="ui header list-header">
						{this.props.category.name}
					</div>
					{this.renderFilters()}
				</div>
				{this.renderList()}
				{this.renderCreateButton()}
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const { headerId, categoryId } = ownProps.match.params;

	return {
		category: state.categories[headerId].items[categoryId],
		lists: state.lists.response,
		loading: state.lists.loading,
		pagination: state.lists.page,
		loadingMore: state.lists.loadingMore,
		allLoaded: state.lists.allLoaded,
		filterId: state.lists.filterId,
		place: state.lists.place
	};
};

export default connect(
	mapStateToProps,
	{ selectCategory, fetchLists, openCreateList }
)(ListPage);
