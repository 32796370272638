import React from 'react';
import { connect } from 'react-redux';
import { selectCategory } from '../../actions/categories';
import { fetchUserLists } from '../../actions/lists';
import { Dropdown } from 'semantic-ui-react';

import Spinner from '../../components/Spinner';
import InfiniteList from '../../components/lists/InfiniteList';

import './UserListsPage.scss';

let username = '';

class UserListsPage extends React.Component {
	componentDidMount() {
		username = '...';
		this.props.selectCategory({});
		this.props.fetchUserLists(1, this.props.match.params.userId);
	}

	componentDidUpdate(prevProps) {
		const { userId } = this.props.match.params;
		if (prevProps.match.params.userId !== userId) {
			this.props.fetchUserLists(1, userId);
		}
	}

	loadMore = () => {
		if (!this.props.loadingMore && !this.props.allLoaded) {
			this.props.fetchUserLists(
				this.props.pagination + 1,
				this.props.match.params.userId,
				this.props.categoryId
			);
		}
	};

	onChangeFilter = (e, { value }) => {
		this.props.fetchUserLists(1, this.props.match.params.userId, value);
	};

	renderDropdown() {
		const options = [{ key: 'all', text: 'All Categories', value: null }];

		this.props.categories.forEach(category => {
			category.items.forEach(item => {
				options.push({ key: item.id, text: item.name, value: item.id });
			});
		});

		return (
			<div className="ui grid">
				<div className="dropdown-user">
					<Dropdown
						defaultValue={this.props.categoryId}
						button
						className="icon violet"
						icon="filter"
						labeled
						placeholder="All Categories"
						scrolling
						options={options}
						onChange={this.onChangeFilter.bind(this)}
					/>
				</div>
			</div>
		);
	}

	renderList() {
		if (this.props.loading) {
			return (
				<div style={{ height: '60vh' }}>
					<Spinner />
				</div>
			);
		}

		return (
			<div className="ui basic segment">
				<InfiniteList
					items={this.props.lists}
					loadMore={this.loadMore}
					loadingMore={this.props.loadingMore}
				/>
			</div>
		);
	}

	render() {
		if (this.props.currentUser.userId === this.props.match.params.userId) {
			username = this.props.currentUser.username;
		} else if (this.props.username) {
			username = this.props.username;
		}

		return (
			<div>
				<div className="ui basic segment center aligned">
					<div className="ui header list-header">{username || '...'}</div>
					{this.renderDropdown()}
				</div>
				{this.renderList()}
			</div>
		);
	}
}

const mapStateToProps = state => {
	let categories = JSON.parse(JSON.stringify(state.categories));
	categories = Object.values(categories);
	categories.map(cat => (cat.items = Object.values(cat.items, 'id')));

	return {
		categories,
		lists: state.lists.response,
		loading: state.lists.loading,
		pagination: state.lists.page,
		loadingMore: state.lists.loadingMore,
		allLoaded: state.lists.allLoaded,
		username: state.lists.user.username,
		categoryId: state.lists.categoryId,
		currentUser: state.auth.user
	};
};

export default connect(
	mapStateToProps,
	{ selectCategory, fetchUserLists }
)(UserListsPage);
