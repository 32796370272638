/* global google */

export const searchPlace = value => {
	if (value.length > 0) {
		const config = {
			types: ['(cities)'],
			input: value
		};

		return new Promise(resolve => {
			const autocompleteService = new google.maps.places.AutocompleteService();
			return autocompleteService.getPlacePredictions(
				config,
				(predictions, status) => {
					if (
						status === google.maps.places.PlacesServiceStatus.OK &&
						predictions
					) {
						const places = [];
						predictions.forEach(prediction => {
							places.push({
								place_id: prediction.place_id,
								title: prediction.description,
								city: prediction.structured_formatting.main_text,
								country: prediction.structured_formatting.secondary_text
							});
						});
						resolve(places);
					} else resolve([]);
				}
			);
		});
	} else {
		return new Promise(resolve => resolve([]));
	}
};

export const selectPlace = address => {
	return new Promise(resolve => {
		const geocoder = new google.maps.Geocoder();
		return geocoder.geocode({ address }, (results, status) => {
			if (status === 'OK') {
				const city = results[0].address_components[0].long_name;
				resolve(city);
			}
		});
	});
};
