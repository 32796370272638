import React from 'react';
import Slider from 'react-slick';

import ListCard from '../lists/ListCard';

class CardSlider extends React.Component {
	render() {
		var settings = {
			arrows: false,
			dots: false,
			infinite: true,
			useTransform: false,
			speed: 1200,
			slidesToShow: 3,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 3000,
			pauseOnHover: false
		};
		return (
			<Slider {...settings}>
				{this.props.lists.map(list => {
					return (
						<div key={list.id} className="ui basic segment">
							<ListCard list={list} />
						</div>
					);
				})}
			</Slider>
		);
	}
}

export default CardSlider;
