import * as app from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
	apiKey: 'AIzaSyDYFSs9dzg8xCswdCHjJQRoqKDM2PfRl64',
	authDomain: 'top-lists-4c34b.firebaseapp.com',
	databaseURL: 'https://top-lists-4c34b.firebaseio.com',
	projectId: 'top-lists-4c34b',
	storageBucket: 'top-lists-4c34b.appspot.com',
	messagingSenderId: '52579104669'
};

app.initializeApp(config);

export const firebase = app;
export const db = app.firestore();
export const auth = app.auth();
export const emailAuthProvider = app.auth.EmailAuthProvider;
export const googleAuthProvider = new app.auth.GoogleAuthProvider();
