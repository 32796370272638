import React from 'react';

class NotFound extends React.Component {
	render() {
		return (
			<div className="ui basic segment">
				<div className="ui center aligned header list-empty">
					<h1>404</h1>
					<h2>Sorry, the page you were trying to view does not exist.</h2>
				</div>
			</div>
		);
	}
}

export default NotFound;
