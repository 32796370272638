import { firebase, db } from '../apis/Firestore';

import { CATEGORY_SELECTED } from './types';

export const selectCategory = category => async dispatch => {
	if (category.categoryId) dispatch(viewCategory(category.categoryId));

	dispatch({
		type: CATEGORY_SELECTED,
		payload: { category }
	});
};

export const viewCategory = categoryId => () => {
	if (localStorage.userId !== '7Zw9uUysi8hIknZVPHwqqArfQSu1') {
		const increment = firebase.firestore.FieldValue.increment(1);

		return db
			.collection('categories')
			.doc(categoryId)
			.update({ views: increment });
	}
};
