export default [
	{
		id: 'entertainment',
		name: 'Entertainment',
		items: [
			{
				id: 'tv',
				name: 'TV Show'
			},
			{
				id: 'movie',
				name: 'Movie'
			},
			{
				id: 'game',
				name: 'Game'
			},
			{
				id: 'app',
				name: 'Mobile App'
			},
			{
				id: 'website',
				name: 'Website & Blog'
			}
		]
	},
	{
		id: 'drink',
		name: 'Drink',
		items: [
			{
				id: 'beer',
				name: 'Beer'
			},
			{
				id: 'wine',
				name: 'Wine'
			},
			{
				id: 'whiskey',
				name: 'Whiskey'
			},
			{
				id: 'rhum',
				name: 'Rhum'
			},
			{
				id: 'cocktail',
				name: 'Cocktail'
			},
			{
				id: 'vodka',
				name: 'Vodka'
			},
			{
				id: 'champagne',
				name: 'Champagne'
			},
			{
				id: 'smoothie',
				name: 'Smoothie'
			}
		]
	},
	{
		id: 'culture',
		name: 'Culture',
		items: [
			{
				id: 'song',
				name: 'Song'
			},
			{
				id: 'artist',
				name: 'Artist'
			},
			{
				id: 'book',
				name: 'Book'
			},
			{
				id: 'manga',
				name: 'Manga'
			},
			{
				id: 'movie_director',
				name: 'Movie Director'
			}
		]
	},
	{
		id: 'travel',
		name: 'Travel',
		items: [
			{
				id: 'country',
				name: 'Country'
			},
			{
				id: 'city',
				name: 'City'
			}
		]
	},
	{
		id: 'city',
		name: 'City',
		items: [
			{
				id: 'bar',
				name: 'Bar'
			},
			{
				id: 'restaurant',
				name: 'Restaurant'
			},
			{
				id: 'club',
				name: 'Club'
			},
			{
				id: 'museum',
				name: 'Museum'
			},
			{
				id: 'hiking',
				name: 'Hiking'
			},
			// {
			// 	id: 'walk',
			// 	name: 'Walk'
			// },
			{
				id: 'beach',
				name: 'Beach'
			},
			{
				id: 'tea',
				name: 'Tea House'
			},
			{
				id: 'bakery',
				name: 'Bakery'
			},
			// {
			// 	id: 'hairdresser',
			// 	name: 'Hairdresser'
			// }
			// {
			// 	id: 'plumber',
			// 	name: 'Plumber'
			// },
			// {
			// 	id: 'locksmith',
			// 	name: 'Locksmith'
			// }
		]
	},
	{
		id: 'food',
		name: 'Food',
		items: [
			{
				id: 'sweet',
				name: 'Sweet'
			},
			{
				id: 'salty',
				name: 'Salty'
			},
			{
				id: 'snack',
				name: 'Snack'
			}
		]
	},
	{
		id: 'home',
		name: 'Home',
		items: [
			{
				id: 'gadget',
				name: 'Gadget'
			},
			{
				id: 'furniture',
				name: 'Furniture'
			},
			{
				id: 'plant',
				name: 'Plant'
			}
		]
	},
	{
		id: 'fun',
		name: 'Fun',
		items: [
			{
				id: 'joke',
				name: 'Jokes'
			},
			{
				id: 'tip',
				name: 'Tips & Tricks'
			},
			{
				id: 'random',
				name: 'Random'
			}
		]
	}
];
