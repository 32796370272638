import React from 'react';
import { Field, reduxForm } from 'redux-form';

class CreateForm extends React.Component {
	renderError({ error, touched }) {
		if (touched && error)
			return (
				<div className="ui error message">
					<div className="header">{error}</div>
				</div>
			);
	}

	renderInput = ({ input, label, meta }) => {
		const className = `field ${meta.error && meta.touched ? 'error' : ''}`;

		return (
			<div className={className}>
			<label>
				<i
					className={`trophy icon icon-trophy trophy-form ${
						label === '1' ? 'yellow' : label === '2' ? 'grey' : 'brown'
					}`}
				/>
				 #{label}
				</label>
				<input {...input} autoComplete="off" />
				{this.renderError(meta)}
			</div>
		);
	};

	onSubmit = formValues => {
		this.props.onSubmit(formValues);
	};

	render() {
		return (
			<form
				id="createForm"
				onSubmit={this.props.handleSubmit(this.onSubmit)}
				className="ui form error"
			>
				<Field name="first" component={this.renderInput} label="1" />
				<Field name="second" component={this.renderInput} label="2" />
				<Field name="third" component={this.renderInput} label="3" />
			</form>
		);
	}
}

const validate = formValue => {
	const errors = {};

	if (!formValue.first) {
		errors.first = 'You must enter a first choice';
	}

	if (!formValue.second) {
		errors.second = 'You must enter a second choice';
	}

	if (!formValue.third) {
		errors.third = 'You must enter a third choice';
	}

	return errors;
};

export default reduxForm({
	form: 'createForm',
	validate
})(CreateForm);
