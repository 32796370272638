import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Menu } from 'semantic-ui-react';

class SideMenu extends React.Component {
	getCategories() {
		return this.props.categories.map(header => {
			return (
				<Menu.Item key={header.id}>
					<Menu.Header className="sidemenu-header">
						<img
							className="img-sidemenu"
							src={`/assets/img/${header.id}.png`}
							alt={header.id}
						/>
						{header.name}
					</Menu.Header>
					{this.getItems(header)}
				</Menu.Item>
			);
		});
	}

	getItems(header) {
		return (
			<Menu.Menu>
				{header.items.map(category => {
					return (
						<Link
							to={`/${header.id}/${category.id}`}
							key={category.id}
							className={`item sidemenu-item ${
								this.props.category.selectedCategory === category.id
									? 'active'
									: ''
							}`}
						>
							{category.name}
						</Link>
					);
				})}
			</Menu.Menu>
		);
	}

	render() {
		return (
			<div>
				<Link to="/" className="header-menu">
					<div className="logo-item">
						<img src="/assets/logo.svg" alt="topmylist" className="logo-img" />
						<div className="logo-text">TopMyList</div>
					</div>
				</Link>
				<div
					className="ui simple inverted sidebar vertical menu left visible overlay side-menu"
					style={{ backgroundColor: '#3b2075' }}
				>
					{this.getCategories()}
					<Menu.Item className="privacy-item">
						<Menu.Menu>
							<a
								className="item privacy-text"
								href="https://www.iubenda.com/privacy-policy/49375038"
								target="_blank"
								rel="noopener noreferrer"
							>
								Privacy Policy
							</a>
						</Menu.Menu>
						<Menu.Menu>
							<a
								className="item privacy-text"
								href="mailto:contact.topmylist@gmail.com"
							>
								Contact Us
							</a>
						</Menu.Menu>
						<Menu.Menu>
							<div className="item privacy-text text-right">Version 1.0</div>
						</Menu.Menu>
					</Menu.Item>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	let categories = JSON.parse(JSON.stringify(state.categories));
	categories = Object.values(categories);
	categories.map(cat => (cat.items = Object.values(cat.items, 'id')));

	return {
		categories,
		category: state.category
	};
};

export default connect(
	mapStateToProps,
	null
)(SideMenu);
