import React from 'react';
import { connect } from 'react-redux';
import {
	signInStart,
	signInError,
	resetPassword,
	resetPasswordClose
} from '../../actions/auth';
import { Button, Modal, TransitionablePortal } from 'semantic-ui-react';

import PasswordForm from './PasswordForm';

import './Auth.scss';

class PasswordModal extends React.Component {
	closeModal = () => this.props.resetPasswordClose();

	onSubmit = async formValues => {
		this.props.signInStart();
		this.props.resetPassword(formValues.email);
	};

	render() {
		return (
			<TransitionablePortal
				open={this.props.openModal}
				onClose={this.closeModal}
				transition={{ animation: 'scale', duration: 500 }}
			>
				<Modal size="tiny" open={true}>
					<Modal.Header>Reset Password</Modal.Header>
					<Modal.Content>
						<Modal.Description>
							<div className="ui basic segment">
								<PasswordForm onSubmit={this.onSubmit} />
								<div className="password-email">
									We will send you an email to reset your password
								</div>
							</div>
						</Modal.Description>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.closeModal}>Cancel</Button>
						<Button
							form="passwordForm"
							color="violet"
							content="Send Email"
							disabled={this.props.loadingAuth}
							loading={this.props.loadingAuth}
						/>
					</Modal.Actions>
				</Modal>
			</TransitionablePortal>
		);
	}
}

const mapStateToProps = state => {
	return {
		loadingAuth: state.auth.loadingAuth,
		openModal: state.auth.openPasswordModal
	};
};

export default connect(
	mapStateToProps,
	{ signInStart, signInError, resetPassword, resetPasswordClose }
)(PasswordModal);
