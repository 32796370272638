import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

class PasswordForm extends React.Component {
	state = { email: '' };

	renderError({ error, touched }, emailError) {
		if (emailError || (touched && error))
			return (
				<div className="ui error message">
					<div className="header">{error || emailError}</div>
				</div>
			);
	}

	renderInput = ({ input, type, label, meta, emailError }) => {
		if (this.state.email !== input.value) emailError = false;

		const isError = emailError || (meta.error && meta.touched);
		const className = `field ${isError ? 'error' : ''}`;

		return (
			<div className={className}>
				<label>{label}</label>
				<input {...input} type={type} autoComplete="off" />
				{this.renderError(meta, emailError)}
			</div>
		);
	};

	onSubmit = formValues => {
		this.setState({ email: formValues.email });
		this.props.onSubmit(formValues);
	};

	render() {
		return (
			<form
				id="passwordForm"
				onSubmit={this.props.handleSubmit(this.onSubmit)}
				className="ui form error"
			>
				<Field
					name="email"
					type="email"
					component={this.renderInput}
					label="Email"
					emailError={this.props.errorMessage}
				/>
			</form>
		);
	}
}

const validate = formValue => {
	const errors = {};

	if (
		!formValue.email ||
		!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValue.email)
	) {
		errors.email = 'You must enter a valid email';
	}

	return errors;
};

const mapStateToProps = state => {
	return {
		errorMessage: state.auth.errorMessage
	};
};

PasswordForm = connect(mapStateToProps)(PasswordForm);

export default reduxForm({
	form: 'authForm',
	validate
})(PasswordForm);
