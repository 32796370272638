import {
	FETCH_LISTS,
	FETCH_LISTS_START,
	FETCH_LISTS_MORE,
	CREATE_LIST_START,
	CREATE_LIST,
	VOTE_LIST_START,
	USER_LISTS,
	CREATE_OPEN,
	CREATE_CLOSE
} from '../actions/types';

const INITIAL_STATE = {
	response: [],
	loading: true,
	user: {},
	categoryId: null,
	filterId: null,
	place: {},
	page: 1,
	favorites: null,
	loadingMore: null,
	lastVisible: null,
	allLoaded: null,
	loadingNew: null,
	openCreateModal: null,
	editList: { list: {} },
	formValues: null
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_LISTS_START:
			return { ...state, ...INITIAL_STATE };
		case FETCH_LISTS_MORE:
			return { ...state, loadingMore: true };
		case FETCH_LISTS:
			return {
				...state,
				response: [...state.response, ...action.payload.lists],
				filterId: action.payload.filterId,
				place: action.payload.place,
				loading: false,
				page: action.payload.page,
				loadingMore: false,
				lastVisible: action.payload.lastVisible,
				allLoaded: action.payload.allLoaded,
				favorites: action.payload.favorites
			};
		case CREATE_LIST_START:
			return { ...state, loadingNew: true };
		case CREATE_LIST:
			return { ...state, loadingNew: false };
		case VOTE_LIST_START:
			return {
				...state,
				response: [
					...state.response.map(el => {
						if (el.id === action.payload.id) {
							el.popularity += action.payload.increment;
						}
						return el;
					})
				]
			};
		case USER_LISTS:
			return {
				...state,
				categoryId: action.payload.categoryId,
				user: { username: action.payload.username }
			};
		case CREATE_OPEN:
			return { ...state, openCreateModal: true, editList: action.payload };
		case CREATE_CLOSE:
			return {
				...state,
				openCreateModal: null,
				formValues: action.payload,
				editList: { list: {} }
			};
		default:
			return state;
	}
};
