/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import {
	signInStart,
	fetchCurrentUser,
	signInError,
	logInClose,
	resetPasswordOpen
} from '../../actions/auth';
import { Button, Modal, TransitionablePortal } from 'semantic-ui-react';
import { auth } from '../../apis/Firestore';

import GoogleAuth from './GoogleAuth';
import LoginForm from './LoginForm';

import './Auth.scss';

class LoginModal extends React.Component {
	onSubmit = async formValues => {
		this.props.signInStart();

		try {
			const authResult = await auth.signInWithEmailAndPassword(
				formValues.email,
				formValues.password
			);

			this.props.fetchCurrentUser(authResult.user.uid);
		} catch (error) {
			this.props.signInError(error.message);
		}
	};

	openResetPassword = () => {
		this.props.logInClose();
		this.props.resetPasswordOpen();
	};

	render() {
		return (
			<TransitionablePortal
				open={this.props.openModal}
				onClose={this.props.logInClose}
				transition={{ animation: 'scale', duration: 500 }}
			>
				<Modal size="tiny" open={true}>
					<Modal.Header>Welcome Back!</Modal.Header>
					<Modal.Content>
						<Modal.Description>
							<div className="ui basic segment center aligned">
								<GoogleAuth />
							</div>
							<div className="ui basic segment">
								<LoginForm onSubmit={this.onSubmit} />
								<a
									href="javascript:;"
									onClick={this.openResetPassword}
									className="forgot-password"
								>
									Forgot Password?
								</a>
							</div>
						</Modal.Description>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.props.logInClose}>Cancel</Button>
						<Button
							form="loginForm"
							color="violet"
							content="Log In"
							disabled={this.props.loadingAuth}
							loading={this.props.loadingAuth}
						/>
					</Modal.Actions>
				</Modal>
			</TransitionablePortal>
		);
	}
}

const mapStateToProps = state => {
	return {
		loadingAuth: state.auth.loadingAuth,
		openModal: state.auth.openLoginModal
	};
};

export default connect(
	mapStateToProps,
	{ signInStart, fetchCurrentUser, signInError, logInClose, resetPasswordOpen }
)(LoginModal);
