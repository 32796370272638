import { db, auth, emailAuthProvider } from '../apis/Firestore';
import { openCreateList } from './lists';

import {
	SIGN_IN_OPEN,
	SIGN_IN_CLOSE,
	LOG_IN_OPEN,
	LOG_IN_CLOSE,
	SIGN_IN_START,
	SIGN_IN_ERROR,
	SIGN_IN,
	SIGN_OUT,
	FETCH_CURRENT_USER,
	PROFILE_OPEN,
	PROFILE_CLOSE,
	UPDATE_PROFILE,
	UPDATE_EMAIL,
	UPDATE_PASSWORD,
	RESET_PASSWORD_OPEN,
	RESET_PASSWORD_CLOSE
} from './types';

export const resetPasswordOpen = () => {
	return {
		type: RESET_PASSWORD_OPEN
	};
};

export const resetPasswordClose = () => {
	return {
		type: RESET_PASSWORD_CLOSE
	};
};

export const signInOpen = () => {
	return {
		type: SIGN_IN_OPEN
	};
};

export const signInClose = () => {
	return {
		type: SIGN_IN_CLOSE
	};
};

export const logInOpen = () => {
	return {
		type: LOG_IN_OPEN
	};
};

export const logInClose = () => {
	return {
		type: LOG_IN_CLOSE
	};
};

export const signInStart = () => {
	return {
		type: SIGN_IN_START
	};
};

export const signInError = message => {
	return {
		type: SIGN_IN_ERROR,
		payload: message
	};
};

export const signIn = authInfo => async (dispatch, getState) => {
	let user = await db
		.collection('users')
		.doc(authInfo.userId)
		.get();

	authInfo.avatarId = Math.floor(10000000 + Math.random() * 900000);

	if (!user.exists) {
		await db
			.collection('users')
			.doc(authInfo.userId)
			.set(authInfo);
	}

	dispatch(fetchCurrentUser(authInfo.userId));

	if (getState().lists.formValues) dispatch(openCreateList());

	dispatch({
		type: SIGN_IN
	});
};

export const fetchCurrentUser = userId => async dispatch => {
	const user = await db
		.collection('users')
		.doc(userId)
		.get();

	dispatch({
		type: FETCH_CURRENT_USER,
		payload: user.data()
	});
};

export const signOut = userId => {
	return {
		type: SIGN_OUT,
		payload: userId
	};
};

export const profileOpen = () => {
	return {
		type: PROFILE_OPEN
	};
};

export const profileClose = () => {
	return {
		type: PROFILE_CLOSE
	};
};

export const updateProfile = (username, avatarId) => async (
	dispatch,
	getState
) => {
	const userId = getState().auth.user.userId;

	await db
		.collection('users')
		.doc(userId)
		.update({ username, avatarId });

	dispatch({
		type: UPDATE_PROFILE,
		payload: { username, avatarId }
	});
};

export const updateEmail = (email, password) => async (dispatch, getState) => {
	// await reAuthenticateUser(email, password);
	await auth.currentUser.updateEmail(email);

	const userId = getState().auth.user.userId;

	await db
		.collection('users')
		.doc(userId)
		.update({ email });

	dispatch({
		type: UPDATE_EMAIL,
		payload: email
	});
};

export const updatePassword = (
	email,
	currentPassword,
	newPassword
) => async dispatch => {
	await reAuthenticateUser(email, currentPassword);
	await auth.currentUser.updatePassword(newPassword);

	dispatch({
		type: UPDATE_PASSWORD
	});
};

export const reAuthenticateUser = async (email, password) => {
	const credential = emailAuthProvider.credential(email, password);

	await auth.currentUser.reauthenticateAndRetrieveDataWithCredential(
		credential
	);
};

export const resetPassword = email => async dispatch => {
	try {
		await auth.sendPasswordResetEmail(email);

		dispatch({
			type: RESET_PASSWORD_CLOSE
		});
	} catch (error) {
		dispatch(signInError(error.message));
	}
};
