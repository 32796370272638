import React from 'react';
import { connect } from 'react-redux';
import { signInOpen, logInOpen, profileOpen } from '../actions/auth';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';

import { auth } from '../apis/Firestore';
import AuthModal from './auth/AuthModal';
import LoginModal from './auth/LoginModal';
import PasswordModal from './auth/PasswordModal';
import ProfilePage from '../pages/ProfilePage/ProfilePage';

class Header extends React.Component {
	renderDropdown() {
		if (this.props.isSignedIn) {
			return (
				<React.Fragment>
					<Dropdown.Header content={this.props.user.username} />
					<Dropdown.Item
						className="item-dropdown"
						onClick={this.props.profileOpen}
					>
						<i className="cog icon grey" />
						Settings
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</Dropdown.Item>
					<Dropdown.Item onClick={() => auth.signOut()}>
						<i className="sign-out icon red" />
						Sign Out
					</Dropdown.Item>
				</React.Fragment>
			);
		} else {
			return (
				<>
					<Dropdown.Item onClick={this.props.signInOpen}>
						<i className="user circle outline icon blue" />
						Sign In
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</Dropdown.Item>
					<Dropdown.Item onClick={this.props.logInOpen}>
						<i className="sign-in icon violet" />
						Log In
					</Dropdown.Item>
				</>
			);
		}
	}

	render() {
		return (
			<div>
				<div className="ui top fixed menu menu-header">
					<div className="right menu">
						{this.props.user.userId && (
							<>
								<div className="menu item menu-item">
									<Link
										to={`/user/lists/${this.props.user.userId}`}
										className="ui primary button"
										style={{ lineHeight: '1' }}
									>
										My Lists
									</Link>
								</div>
								<div className="menu item menu-item">
									<Link
										to="/favorites"
										className="ui yellow button"
										style={{ lineHeight: '1' }}
									>
										Favorites
									</Link>
								</div>
							</>
						)}
						<Dropdown item icon="bars" pointing>
							<Dropdown.Menu>{this.renderDropdown()}</Dropdown.Menu>
						</Dropdown>
					</div>
				</div>
				<AuthModal />
				<LoginModal />
				<ProfilePage />
				<PasswordModal />
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.auth.user,
		isSignedIn: state.auth.isSignedIn
	};
};

export default connect(
	mapStateToProps,
	{ signInOpen, logInOpen, profileOpen }
)(Header);
