import React from 'react';
import Particles from 'react-particles-js';

import './Particles.scss';

const particlesOptions = {
	particles: {
		number: {
			value: 350
		},
		color: {
			value: '#ffdf0f'
		},
		opacity: {
			value: 1
		},
		size: {
			value: 2,
			random: true
		},
		line_linked: {
			enable: false
		}
	}
};

const ParticlesContainer = () => {
	return <Particles className="particles" params={particlesOptions} />;
};

export default ParticlesContainer;
