import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

class ProfileForm extends React.Component {
	state = { email: '', showPassword: true };

	renderError({ error, touched }, emailError) {
		if (emailError || (touched && error))
			return (
				<div className="ui error message">
					<div className="header">{error || emailError}</div>
				</div>
			);
	}

	renderInput = ({ input, type, label, meta, emailError }) => {
		if (this.state.email !== input.value) emailError = false;

		const isError = emailError || (meta.error && meta.touched);
		const className = `field ${isError ? 'error' : ''}`;

		return (
			<div className={className}>
				<label>{label}</label>
				<input {...input} type={type} autoComplete="off" />
				{this.renderError(meta, emailError)}
			</div>
		);
	};

	onSubmit = formValues => {
		this.setState({ email: formValues.email });
		this.props.onSubmit(formValues);
	};

	renderPassword() {
		if (this.state.showPassword) {
			return (
				<>
					<Field
						name="newPassword"
						type="password"
						component={this.renderInput}
						label="New Password"
					/>
					<Field
						name="newPasswordConfirm"
						type="password"
						component={this.renderInput}
						label="Confirm New Password"
					/>
				</>
			);
		}
	}

	onChangeEmail = e => {
		this.setState({
			showPassword: e.target.value === this.props.initialValues.email
		});
	};

	render() {
		return (
			<form
				id="createForm"
				onSubmit={this.props.handleSubmit(this.onSubmit)}
				className="ui form error"
			>
				<Field name="username" component={this.renderInput} label="Username" />
				<Field
					name="email"
					type="email"
					component={this.renderInput}
					label="Email"
					emailError={this.props.errorMessage}
					onChange={e => this.onChangeEmail(e)}
				/>
				<Field
					name="currentPassword"
					type="password"
					component={this.renderInput}
					label="Current Password"
				/>
				{this.renderPassword()}
			</form>
		);
	}
}

const validate = (formValue, props) => {
	const errors = {};

	if (!formValue.username) {
		errors.username = 'You must enter a username';
	}

	if (
		!formValue.email ||
		!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValue.email)
	) {
		errors.email = 'You must enter a valid email';
	}

	if (
		formValue.email !== props.initialValues.email &&
		!formValue.currentPassword
	) {
		errors.currentPassword =
			'You must enter your password to change your email';
	}

	if (formValue.currentPassword && formValue.currentPassword.length < 6) {
		errors.currentPassword =
			'You must enter a password with at least 6 characters';
	}

	if (formValue.newPassword && formValue.newPassword.length < 6) {
		errors.newPassword = 'You must enter a password with at least 6 characters';
	}

	if (formValue.newPassword !== formValue.newPasswordConfirm) {
		errors.newPasswordConfirm = 'Your passwords do not match';
	}

	return errors;
};

const mapStateToProps = state => {
	return {
		currentUser: state.auth.user,
		errorMessage: state.auth.errorMessage
	};
};

ProfileForm = connect(mapStateToProps)(ProfileForm);

export default reduxForm({
	form: 'profileForm',
	validate
})(ProfileForm);
