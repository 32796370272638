import {
	FETCH_TOPS,
	FETCH_TOPS_START,
	VOTE_LIST_START,
	FETCH_LISTS_START
} from '../actions/types';

const INITIAL_STATE = {
	response: [],
	loading: true
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_TOPS_START:
			return { ...state, ...INITIAL_STATE };
		case FETCH_TOPS:
			return {
				...state,
				response: [...state.response, ...action.payload],
				loading: false
			};
		case VOTE_LIST_START:
			return {
				...state,
				response: [
					...state.response.map(el => {
						if (el.id === action.payload.id) {
							el.popularity += action.payload.increment;
						}
						return el;
					})
				]
			};
		case FETCH_LISTS_START:
			return { ...state, response: [] };
		default:
			return state;
	}
};
