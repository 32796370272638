import _ from 'lodash';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import authReducer from './auth';
import categoryReducer from './category';
import listReducer from './lists';
import topReducer from './tops';

import menuCategories from '../data/menuCategories';

const categoriesReducer = () => {
	menuCategories.map(cat => (cat.items = _.mapKeys(cat.items, 'id')));
	return _.mapKeys(menuCategories, 'id');
};

export default combineReducers({
	auth: authReducer,
	categories: categoriesReducer,
	category: categoryReducer,
	lists: listReducer,
	tops: topReducer,
	form: formReducer
});
