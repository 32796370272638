import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from '../history';

import { auth } from '../apis/Firestore';
import { connect } from 'react-redux';
import { signIn, signOut } from '../actions/auth';

import SideMenu from './SideMenu';
import Header from './Header';
import HomePage from '../pages/HomePage/HomePage';
import ListsPage from '../pages/ListsPage/ListsPage';
import FavoritesPage from '../pages/FavoritesPage/FavoritesPage';
import UserListsPage from '../pages/UserListsPage/UserListsPage';
import NotFound from '../pages/NotFound/NotFound';
import Particles from './Particles/Particles';
import CreateModal from './create/CreateModal';

import './App.scss';

class App extends React.Component {
	componentDidMount() {
		auth.onAuthStateChanged(user => {
			if (user) {
				this.props.signIn({ userId: user.uid });
			} else {
				this.props.signOut();
			}
		});
	}

	render() {
		return (
			<Router history={history}>
				<SideMenu />
				<div className="pusher content-pusher">
					<Header />
					<Switch>
						<Route path="/" exact component={HomePage} />
						<Route path="/:headerId/:categoryId" exact component={ListsPage} />
						<Route path="/favorites" exact component={FavoritesPage} />
						<Route path="/user/lists/:userId" exact component={UserListsPage} />
						<Route path="*" component={NotFound} />
					</Switch>
					<Particles />
					<CreateModal />
				</div>
			</Router>
		);
	}
}

const mapStateToProps = state => {
	return { isSignedIn: state.auth.isSignedIn };
};

export default connect(
	mapStateToProps,
	{ signIn, signOut }
)(App);
