import React from 'react';
import { Search, Icon } from 'semantic-ui-react';
import { searchPlace } from '../../apis/Gmaps';

class Location extends React.Component {
	state = { isLoading: false, results: [], value: '' };
	componentDidMount() {
		this.updateCityValue();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.place !== this.props.place) {
			this.updateCityValue();
		}
	}

	updateCityValue() {
		if (this.props.place && this.props.place.city) {
			this.setState({
				value: `${this.props.place.city}, ${this.props.place.country}`
			});
		}
	}

	handleResultSelect = async (e, { result }) => {
		this.setState({ value: result.title });
		this.props.selectPlace(result);
	};

	handleSearchChange = async (e, { value }) => {
		this.setState({ isLoading: true, value });

		const result = await searchPlace(value);

		this.setState({
			isLoading: false,
			results: result
		});
	};

	handleDeleteClick = () => {
		this.setState({ value: '' });
		this.props.selectPlace({});
	};

	render() {
		const { isLoading, value, results } = this.state;

		return (
			<Search
				icon={
					<Icon
						name={this.state.value ? 'close' : 'search'}
						link
						onClick={this.handleDeleteClick}
					/>
				}
				placeholder="Search City"
				loading={isLoading}
				onResultSelect={this.handleResultSelect}
				onSearchChange={this.handleSearchChange}
				results={results}
				value={value}
			/>
		);
	}
}

export default Location;
